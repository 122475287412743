import { provideHttpClient, withXsrfConfiguration } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  inject,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { FmntsBrowserModule } from '@fmnts/common/browser';
import { FmntsDefaultIconsLib } from '@fmnts/components/icons';
import { routes } from './app.routes';
import { PublicAppConfig } from './public-app.config';
import { PublicLocalizationModule } from './public-l10n.module';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      FmntsBrowserModule,
      PublicLocalizationModule,
      FmntsDefaultIconsLib,
    ),
    provideHttpClient(
      withXsrfConfiguration({
        cookieName: 'csrftoken',
        headerName: 'X-CSRFToken',
      }),
    ),
    provideRouter(routes),
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory:
        (config = inject(PublicAppConfig)) =>
        () =>
          config.init$(),
    },
  ],
};
