import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApiConfigService } from '@fmnts/api';
import { iif, Observable, of, shareReplay, tap, zip } from 'rxjs';
import { environment } from '../environments/environment';
import {
  FmntsApiConfig,
  IPublicAppEnvironment,
} from '../environments/environment.type';

/**
 * Holds the configuration and environment for `@fmnts/public`.
 * Provides facilities to use with `APP_INITIALIZER`.
 */
@Injectable({ providedIn: 'root' })
export class PublicAppConfig {
  private readonly http = inject(HttpClient);
  private readonly apiConfig = inject(ApiConfigService);

  private readonly _env: Readonly<IPublicAppEnvironment> = environment;

  /**
   * Compile-time app environment
   */
  public get env(): Readonly<IPublicAppEnvironment> {
    return this._env;
  }

  /**
   * A multicasting observable that emits with the loaded
   * settings. Late subscribes will immediately receive
   * the value.
   */
  public settings$ = iif(
    () => this._env.production,
    this.http.get<FmntsApiConfig>('assets/settings.json'),
    of(this._env.settings),
  ).pipe(
    tap((settings) => {
      this.apiConfig.apiUrl = settings.apiUrl;
    }),
    shareReplay(1),
  );

  private _init$ = zip(this.settings$);

  /**
   * Use this function in the app initialization phase to
   * ensure that the app configuration is available.
   *
   * @returns
   * An `Observable` that emits when everything is initialized.
   */
  public init$(): Observable<any> {
    return this._init$;
  }
}
