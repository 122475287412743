import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./donor-feedback/donor-feedback.routes').then(
        (m) => m.donorFeedbackRoutes,
      ),
  },
];
