import { IPublicAppEnvironment } from './environment.type';

export const environment: IPublicAppEnvironment = {
  production: true,
  name: 'prod',

  settings: {
    apiUrl: '',
  },
};
